import React from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './custom-progress-style.css';
import BookNowButton from './BookNowButton';

const styles = {
    offerTitle: {
        fontFamily: "TamilMN",
        fontSize: 10,
        letterSpacing: "0.63px",
        textTransform: "uppercase"
    },
    offerSubtitle: {
        fontFamily: "Lato",
        fontSize: 10,
        letterSpacing: "0.63px",
        textTransform: "uppercase"
    },
    pointsRequiredText: {
        fontFamily: "Lato",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 2.2
    },
    routeText: {
        fontFamily: "Lato",
        fontSize: 10,
        letterSpacing: "0.63px",
        lineHeight: 1.8,
        textTransform: "uppercase"
    },
};
 
function santizeRoute(route) {
    if (route === undefined || route.length < 2) {
        return ["",""];
    }
    return route;
}

function createBrandImgPath(type, brand) {
    // I don't like how this is hard coded to datbase id at all...but we have to find the files somehow
    // TODO find a better solution here
    const redemptionMap = {
                        "credit-card": "Airlines/",
                        "airlines": "Airlines/",
                        "hotels": "Hotels/"
                    }
    const filePrefix = "/images/logos/";
    const fileExt = ".svg";
    const brandFilename = brand.toLowerCase().replace(/ /g, "-");
    return filePrefix + redemptionMap[type] + brandFilename + fileExt;
}

class OfferProgress extends React.Component {
    constructor(props) {
        super(props);
        let cleanRoute = santizeRoute(props.route); 
        let cleanDir = props.direction;
        this.state = {
            route: cleanRoute,
            direction: cleanDir
        }
    }

    buildLogo(redemptionType, brand) {
        if (redemptionType === undefined || brand === undefined) {
            console.log("This brand is messed up, bailing");
            return;
        }
        let backupImgPath = redemptionType === "hotels" ? "/images/logos/world.svg" : "/images/logos/plane.svg";
        return <img style={{ width: 48, marginTop: -40 }} src={createBrandImgPath(redemptionType, brand)} alt="brand_icon" onError={(e)=>{e.target.onerror = null; e.target.src=backupImgPath}}/>;
    }

    buildOfferInfoSection() {
        if (this.props.route === undefined) {
            // Hotels case
            return <Typography style={styles.routeText}>{this.props.stay}</Typography>
        } else {
            let tripDirection = this.state.route[0] +  " → " + this.state.route[1];    
            return  (
                        <div>
                            <Typography style={styles.routeText}>{tripDirection}</Typography>
                            <Typography style={styles.routeText}>{this.state.direction}</Typography>
                        </div>
                    );
        }
    }

    render() {
        const userPoints = this.props.program.userPoints;
        const pointsNeeded = this.props.pointsNeeded;
        let percentage = parseInt(userPoints/pointsNeeded * 100);
        percentage = percentage > 100 ? 100 : percentage;
        let line2 = this.props.offer;
        if (line2 === undefined) {
            line2 = this.props.destination;
        }
        const showBookNow = percentage === 100;
        // TODO This approach doesn't scale well...we should consider an inheritance approach or pass this from parent
        return (
            <Box display="inline-block" align='center' style={{maxWidth:120}}>
                <div style={{ width: 120, height: 80 }}>
                {/* TODO these dimensions work better for mobile <div style={{ width: 140, height: 80 }}> */}
                    <CircularProgressbarWithChildren value={percentage} circleRatio={0.5} strokeWidth={5}
                        styles={{
                            root:{},
                            path: {
                                stroke: `rgb(51, 51, 51)`,
                                strokeLinecap: "butt",
                                transform: "rotate(0.75turn)",
                                transformOrigin: 'center center',
                            },
                            trail: {
                                // stroke-dasharray is overriden by package so we need to !important override see custom-progress-style.css
                                stroke: `rgb(51, 51, 51)`,
                                strokeWidth: 0.5,
                                strokeLinecap: "butt",
                                transform: "rotate(0.75turn)",
                                transformOrigin: 'center center',
                                clipPath: "inset(0 0 0 50%)" 
                            },
                            text: {
                                color: "#333333",
                            }
                        }}>
                        {this.buildLogo(this.props.redemptionType, this.props.brand)}
                    </CircularProgressbarWithChildren>
                </div>
                <Typography style={styles.offerTitle}>{this.props.brand}</Typography>
                <Typography style={styles.offerSubtitle}>{line2}</Typography>
                <Typography style={styles.pointsRequiredText}>{this.props.pointsNeeded.toLocaleString()} pts</Typography>
                {this.buildOfferInfoSection()}
                <BookNowButton visible={showBookNow} bookWith={this.props.bookWith}/>
            </Box>
        );
    }
}

export default OfferProgress;