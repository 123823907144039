import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box'

import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'

const styles = theme => ({
    root: {
        position: 'absolute',
        top: 80,
        left: '12.5%',
        width: '75%',
        maxHeight: '80%',
        overflowY:'auto',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        outline: 0,
        padding: theme.spacing(2, 4, 3),
        [theme.breakpoints.down('xs')]: {
            left:16,
            top:50,
            width:"90%"
        },
        '& .MuiFormHelperText-root.Mui-error': {
            backgroundColor: theme.palette.background.paper,
            margin:'0',
            padding:"2px 8px"
        }
    },
    textField: {
        color: "red",
        backgroundColor: "white",
        margin: "8px",
    },
});


class PointsEditModal extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            rewardPrograms : props.rewardPrograms,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (state.rewardPrograms !== props.rewardPrograms) {
          return {
                rewardPrograms : props.rewardPrograms,
          };
        }
        // Return null to indicate no change to state.
        return null;
    }

    validateInputs(event) {
        return this.state.rewardPrograms.every((program) => {
                                return !isNaN(program.userPoints)
                            });
    }

    handleInputChange(event) {
        const newPoints = event.target.value;
        const programId = event.target.name;
        let updatedPrograms = this.state.rewardPrograms;
        var index = updatedPrograms.map(function(e) { return e.id; }).indexOf(programId);
        if (index !== -1) {
            updatedPrograms[index].userPoints = parseInt(newPoints);
        }
        this.setState({
            rewardPrograms: updatedPrograms,
        });
    }

    handleSubmit = (event) => {
        if (!this.validateInputs(event)) {
            return;
        }
        this.props.onProgramChange(event, this.state.rewardPrograms);
        this.props.onModalClose(event);
    }

    handleModalClose = (event) => {
        this.props.onModalClose(event);
    }

    createInputFields(classes, rewardPrograms) {
        let inputFields = [];
        if (rewardPrograms === undefined || rewardPrograms.length === 0) {
            inputFields.push(<InputLabel key="no_programs" color="primary">No Reward Programs Found.</InputLabel>);
        } else {
            for (let i=0; i<rewardPrograms.length; i++) {
                const program = rewardPrograms[i];
                inputFields.push(<InputLabel key={program.id + "_" + i + "_inputLabel"}>
                                    {program.info["Name"]}
                                </InputLabel>);
                inputFields.push(<TextField 
                    id="outlined-full-width"
                    label="Current Points"
                    variant="outlined"
                    key={program.id + "_" + i + "_modalInput"}
                    className={classes.textField}
                    name={program.id}
                    onChange={this.handleInputChange}
                    helperText={isNaN(program.userPoints) ? "Please Enter A Number" : ''}
                    defaultValue={program.userPoints}
                    error={isNaN(program.userPoints)}
                    fullWidth/>);
                      // Alternative method closer to design
                        /* <Grid container style={{padding:"20px 15px"}}>
                                <Grid item sm={6} xs={12}>
                                    <TextField id="outlined-full-width" label="Outlined" variant="outlined" fullWidth />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <InputLabel>Test</InputLabel>
                                    <TextField id="outlined-full-width" label="Outlined" variant="outlined" fullWidth/>
                                </Grid>
                            </Grid> */
            }    
        }
        return inputFields;
    }
    render() {
        const { classes, rewardPrograms} = this.props;
        let inputFields = this.createInputFields(classes, rewardPrograms);
        return (
            <Modal
                open={this.props.openModal}
                onClose={this.handleModalClose}
                classes={classes.Modal}
              >
                <Card className={classes.root}>
                    <Box mb={2}>
                        <Grid container style={{padding:"20px 15px"}} justify="space-between">
                            <Grid item>
                                <Typography align='left' variant="h5" display="inline" justify="space-between">Credit Card Points Overview</Typography>
                            </Grid>
                            <Grid item>
                                <Typography align='right' display="inline" justify="space-between">Please enter your points per program</Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Box>
                    <form noValidate autoComplete="off">
                        {inputFields}
                        <Grid container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-end">
                            <Button variant="contained"
                                    color="secondary"
                                    onClick={this.handleSubmit}>
                                Save
                            </Button>
                        </Grid>
                    </form>
                </Card>
            </Modal>
        );
    }
}

export default withStyles(styles)(PointsEditModal);