import React from 'react'
import { withStyles } from "@material-ui/core/styles";
import OfferProgress from './OfferProgress';
import Typography from '@material-ui/core/Typography';
import {Box, Grid} from '@material-ui/core';
import RedemptionEvaluator from '../RedemptionEvaluator';

const styles = theme => ({
    progressBarGrid: {
        [theme.breakpoints.down('sm')]: {
            width:'100%',
            margin: 'auto',
            justifyContent: 'space-evenly',
            '& > .MuiGrid-item': {
              padding: "5px",
            },
          },
    },
    offerErrorMessage: {
        textAlign: 'left',
        maxWidth: 450,
        paddingTop: 20,
        [theme.breakpoints.down('xs')]: {
            padding: 5,
        }
    }
});

class OfferProgressContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            offers : {},
            prefArrival: null,
            prefDeparture: null
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.prefArrival !== nextProps.userInfo["goalArrival"]) {
          return {
                prefArrival : nextProps.userInfo["goalArrival"],
          };
        }
        if (prevState.prefDeparture !== nextProps.userInfo["goalDeparture"]) {
            return {
                prefDeparture : nextProps.userInfo["goalDeparture"],
            };
        }
        // Return null to indicate no change to state.
        return null;
    }

    /**
     * This is where we run the ranking algorithm, it will be called on each render
     * @param {*} programId 
     * @param {*} programType 
     */
    getProgramsToRender(programId, programType) {
        //console.log("Running algorithm");
        const matchingOffers = this.state.offers[programId];
        const userOfferPoints = this.props.programs[this.props.selected].userPoints;
        if (matchingOffers === undefined) {
            return [];
        }
        // console.log("Offers: " + JSON.stringify(matchingOffers));
        let rankedOffers = null;
        if (programType === "credit-card") {
            rankedOffers = RedemptionEvaluator.filterAndRankCCardOffers(matchingOffers, userOfferPoints);
        } else if (programType === "airlines") {
            rankedOffers = RedemptionEvaluator.filterAndRankAirOffers(matchingOffers, userOfferPoints);
        } else if (programType === "hotels") {
            rankedOffers = RedemptionEvaluator.filterAndRankHotelOffers(matchingOffers, userOfferPoints);
        } else {
            console.log("ERROR: Program type issue found.");
        }
        if (rankedOffers != null) {
            let validOffers = rankedOffers.filter((offer) => offer != null);
            return validOffers;
        } else {
            return [];
        }
    }

    updateMatchingOffersForHotels() {
        var db = this.props.firebase.app.firestore();
        console.log("DEBUG: Searching for Hotel redemptions for arrival=" + this.props.userInfo["goalArrival"])
        db.collection(this.props.dataId)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    db.collection(this.props.dataId)
                        .doc(doc.id)
                        .collection("offers")
                        .where("Arrival Region", "==", this.props.userInfo["goalArrival"])
                        .get()
                            .then((docSnap) => {
                                console.log("DEBUG: Found " + docSnap.size + " hotel offers for program id " + doc.id + " arrival=" + this.props.userInfo["goalArrival"]);
                                let matchingOffers = [];
                                for (let index = 0; index < docSnap.size; index++) {
                                    const offer = docSnap.docs[index];
                                    matchingOffers.push(offer.data());
                                }
                                let newState = this.state;
                                newState.offers[doc.id] = matchingOffers;
                                this.setState(newState);
                            });
                });
            });
    }

    updateMatchingOffersForFlights() {
        var db = this.props.firebase.app.firestore();
        console.log("DEBUG: Searching for redemptions for depart=" + this.props.userInfo["goalDeparture"] + " arrival=" + this.props.userInfo["goalArrival"])
        db.collection(this.props.dataId)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    db.collection(this.props.dataId)
                        .doc(doc.id)
                        .collection("offers")
                        .where("Arrival Region", "==", this.props.userInfo["goalArrival"])
                        .where("Departure Hub City", "==", this.props.userInfo["goalDeparture"])
                        .get()
                            .then((docSnap) => {
                                console.log("DEBUG: Found " + docSnap.size + " offers for program id " + doc.id + " depart=" + this.props.userInfo["goalDeparture"] + " arrival=" + this.props.userInfo["goalArrival"]);
                               let matchingOffers = [];
                                for (let index = 0; index < docSnap.size; index++) {
                                    const offer = docSnap.docs[index];
                                    matchingOffers.push(offer.data());
                                }
                                let newState = this.state;
                                newState.offers[doc.id] = matchingOffers;
                                this.setState(newState);
                            });
                });
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.prefDeparture == null 
            || this.state.prefArrival == null  ) {
            // console.log("No user preference found, aborting Firebase calls");
            return;
        }

        // Check to see if we've already queries
        if (this.state.prefDeparture === prevState.prefDeparture
            && this.state.prefArrival === prevState.prefArrival ) {
            // console.log("We've already queried firebase, skip the call");
            return;
        }
        // console.log("User preferences found! Querying Firebase");
        if (this.props.dataId === "credit-card" || this.props.dataId === "airlines") {
            this.updateMatchingOffersForFlights();
        } else if (this.props.dataId === "hotels") {
            this.updateMatchingOffersForHotels();
        }
    }

    createGridItems(classes) {
        let offerElements;
        if (this.props.programs.length < 1) {
            offerElements = <Grid item key="loading"><Typography>Loading please wait</Typography></Grid>;
        } else {
            const selectedProgram = this.props.programs[this.props.selected];
            const programKey = selectedProgram.id;
            const offersToDisplay = this.getProgramsToRender(programKey, this.props.dataId);
            const numOffers = offersToDisplay.length;
            if (numOffers < 1) {
                if (this.state.prefArrival != null && this.state.prefDeparture!=null){
                    // No offers for a program. Display coming soon message
                    return(
                        <Grid item key={programKey + "_no_offers_found"}>
                            <Box className={classes.offerErrorMessage}>
                                <Typography variant="overline" component="h3">MORE FEATURES ARRIVING SOON</Typography>
                                <Typography variant="h6">We're working on exciting features to enhance your points journey, including best value award redemptions for this program. Stay connected on Instagram @jetalbert or join our mailing list for arrival information.</Typography>
                            </Box>
                        </Grid>
                    );
                } else {
                    // Otherwise the user hasn't filled in the required selection
                    return(
                        <Grid item key={programKey + "_no_offers_found"}>
                            <Box className={classes.offerErrorMessage}>
                                <Typography variant="overline" component="h1">START YOUR POINTS JOURNEY</Typography>
                                <Typography variant="h6">To begin, select or modify your departure city and arrival region above. Then, input your aspirational points balance to view a curated selection of award redemptions. Repeat to enjoy expanded search results.</Typography>
                            </Box>
                        </Grid>
                    );
                }
            }
            offerElements = [];
            for (let index = 0; index < numOffers; index++) {
                const offerdata = offersToDisplay[index];
                const offer = <Grid item key={programKey + "_" + index} style={{paddingBottom:20, paddingTop:16}}>
                                <OfferProgress  program={selectedProgram}
                                                redemptionType={this.props.dataId}
                                                brand={offerdata["Brand"]}
                                                offer={offerdata["Product"]}
                                                pointsNeeded={offerdata["Points Needed"]}
                                                route={offerdata["Route"]}
                                                direction={offerdata["Direction"]}
                                                destination={offerdata["Destination"]}
                                                stay={offerdata["Stay"]}
                                                bookWith={offerdata["Book With"]}
                                    />
                              </Grid>
                offerElements.push(offer);
            }
        }
        return offerElements;
    }

    render() {
        const { classes } = this.props;

        return(
            <Box mt={2}>
                <Grid container spacing={3} justify="center" className={classes.progressBarGrid}>
                    {this.createGridItems(classes)}
                </Grid>            
            </Box>
        );
    }
}

export default withStyles(styles)(OfferProgressContainer);