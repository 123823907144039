import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCDt01HAlQ0t9YIYVTWPb02y6IulyGsfbg",
    authDomain: "jetalbert-5fdeb.firebaseapp.com",
    databaseURL: "https://jetalbert-5fdeb.firebaseio.com",
    projectId: "jetalbert-5fdeb",
    storageBucket: "jetalbert-5fdeb.appspot.com",
    messagingSenderId: "925680601952",
    appId: "1:925680601952:web:1a87e9764a9e77805f2c05",
    measurementId: "G-VYP0FB2N7T"
  };

class Firebase {
    app;
    analytics
    constructor() {
        //console.log("In firebase constructor");
        this.app = firebase.initializeApp(firebaseConfig);
        this.analytics = firebase.analytics();
    }
}

export default Firebase;