import React from 'react'
import { withStyles } from "@material-ui/core/styles";
import {Card, CardHeader, CardContent} from '@material-ui/core'

import List from '@material-ui/core/List';
import {Grid} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import PointTotalCircle from './PointTotalCircle'

const styles = theme => ({
    pointsTotalGrid: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-evenly',
            margin: 'auto',
            '& > .MuiGrid-item': {
                padding: "5px",
            },
          },
    }
});

class PointsTotalCard extends React.Component {

    formatDollar(number) {
        var pointText = number.toLocaleString();
        pointText = '$' + pointText;
        return pointText;
    }
    render() {
        const cardPoints = this.props.userInfo["credit-card"];
        const airlinePoints = this.props.userInfo["airlines"];
        const hotelPoints = this.props.userInfo["hotels"];
        let cashbackCalc = Math.floor(cardPoints / 100);
        const { classes } = this.props;
        return (
            <Card className="pointsCard" elevation={0} >
              <CardHeader   title="Your Virtual Wallet"
                            titleTypographyProps={{
                                variant:'h6'
                            }}/>
              <Divider />
              <CardContent style={{padding:'16px 0px'}}>
                  <Grid container>
                    <Grid item sm={4} xs={12}>
                        <List component="nav" style={{padding:"10px 0px"}} >
                            <ListItem>
                                <ListItemText primary='Total Credit Card Points'/>
                                <ListItemText primary={cardPoints.toLocaleString()} align='right'/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary='Total Airline Miles'/>
                                <ListItemText primary={airlinePoints.toLocaleString()} align='right'/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary='Total Hotel Points'/>
                                <ListItemText primary={hotelPoints.toLocaleString()} align='right'/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary='Estimated Credit Card Cashback'/>
                                <ListItemText primary={this.formatDollar(cashbackCalc)} align='right'/>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item container sm={8} xs={12} spacing={3} justify="center" className={classes.pointsTotalGrid} alignItems="center">
                        <Grid item key="ccard_total">
                            <PointTotalCircle pointAmount={cardPoints} line1="Total Credit" line2="Card Points" />
                        </Grid>
                        <Grid item key="airline_total">
                            <PointTotalCircle pointAmount={airlinePoints} line1="Total" line2="Airline Miles" />
                        </Grid>
                        <Grid item key="hotel_total">
                            <PointTotalCircle pointAmount={hotelPoints} line1="Total" line2="Hotel Points" />
                        </Grid>
                        <Grid item key="cashback_total">
                            <PointTotalCircle pointAmount={cashbackCalc} line1="Credit Card" line2="Cashback" isDollar={true}/>
                        </Grid>
                    </Grid>
                  </Grid>
              </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(PointsTotalCard);