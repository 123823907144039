import React from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Popper from '@material-ui/core/Popper'
import IconButton from '@material-ui/core/IconButton';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import OfferProgressContainer from './OfferProgressContainer';    
import PointsEditModal from './PointsEditModal';
import { FirebaseContext } from './Firebase';
import { Link, Box, ClickAwayListener } from '@material-ui/core';
import * as html2canvas from 'html2canvas';
import ReactGA from 'react-ga';

const styles = theme => ({
    header: {
        [theme.breakpoints.down('sm')]: {
          display: 'block',
          "& .MuiIconButton-root": {
            padding:5
          }
        },
    },
    root: {
      margin: "10px 0",
      paddingTop:0,
      paddingBottom:0,
      "&.Mui-selected": {
        backgroundColor: "#333333",
        color: "#ffffff",
        borderRadius: 14,
        fontWeight: 600
      },  
      "&.Mui-selected:hover": {
        backgroundColor: "#333333"
      }
    },
    button: {
      transition: "none",
      "&:hover": {
        borderRadius: 14
      },
    },
    Modal: {
        position: 'absolute',
        top: '50%',
        left: '40%',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        outline: 0,
        padding: theme.spacing(2, 4, 3),
    },
    expandButton: {
        textDecoration: 'underline',
        letterSpacing: 1,
        fontSize: 10
    },
    helpBox: {
      maxWidth:"450px",
      display:"flex",
      borderRadius:"10px",
      marginRight:10,
      textAlign:"justify",
    },
    topOfTree: {
      '& .MuiCardHeader-root': {
        padding: 12,
        paddingBottom: 0
      },
      '& .MuiCardActions-root': {
        padding: "8px 0px"
      }
    },
    exportButton: {
      [theme.breakpoints.down('xs')]: {
          display:'none'
      }
    }
  });

function recordPageDownloadAction(category, action, label) {
  ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
}

class PointsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex : 0,
            helpText: "",
            expanded : false,
            openModal: false,
            openTooltip: false,
            tooltipAnchor: null,
            programs: [],
        };
    }

    componentDidMount() {
      var db = this.props.firebase.app.firestore();
      db.collection(this.props.dataId)
          .get()
          .then((querySnapshot) => {
            let programData = [];
            querySnapshot.forEach((doc) => {
              // Default is 0, for testing you could make userPoints a random number
              // Moving forward after account creation this is the value to be updated
              const program = {
                                "id": doc.id,
                                "info": doc.data(),
                                "userPoints": 0
                              };
              programData.push(program);
            });
            this.setState({
              programs: programData
            });
            // TODO this will need to be wired when ready
            this.props.onUserInfoUpdate(null, programData, this.props.dataId);
          });
      }

    handleListItemClick (event, index){
        const newState = this.state;
        newState["selectedIndex"] = index;
        this.setState(newState);
    };

    handleExpandClick (e) {
        const newState = this.state;
        newState["expanded"] = !this.state.expanded;
        this.setState(newState);
    };

    handleEditClick(event) {
      const newState = this.state;
      newState["openModal"] = true;
      this.setState(newState);
    }

    handleHelpClick(event) {
      const newState = this.state;
      newState["openTooltip"] = !this.state.openTooltip;
      newState["tooltipAnchor"] = event.currentTarget;
      this.setState(newState);
    }

    handleExportClick(event) {
      recordPageDownloadAction("Site Action", "Download Pressed", "User pressed download button");
      html2canvas(document.querySelector("#screenshotSection"), {scrollY: -window.scrollY, backgroundColor: '#fffef3', logging: true})
        .then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          var link = document.createElement('a');
          if (typeof link.download === "string") {
            link.href = imgData;
            link.download = 'JETALBERT Points Inspiration Tool.png' 
            // Add the document to the page temporarily then remove
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
              window.open(imgData);
          }
      });
    }

    handleModalClose(event) {
      const newState = this.state;
      newState["openModal"] = false;
      this.setState(newState);
    }

    handleHelpClickAway(event) {
      const newState = this.state;
      newState["openTooltip"] = false;
      this.setState(newState);
    }

    handleProgramChange(event, programs) {
      const newState = this.state;
      newState["programs"] = programs;
      this.setState(newState);
      this.props.onUserInfoUpdate(event, programs, this.props.dataId);
    }

    createProgramListItems(classes) {
      let programList = [];
      const rewardPrograms = this.state.programs;
      if (rewardPrograms === undefined || rewardPrograms.length === 0) {
        programList.push(<ListItem key="loading_programs">Searching for programs...</ListItem>);
      } else {
        for(let i=0; i<rewardPrograms.length; i++) {
          const program = rewardPrograms[i];
          programList.push(<ListItem
                            button
                            selected={this.state.selectedIndex === i}
                            onClick={(event) => this.handleListItemClick(event, i)}
                            classes = {{root: classes.root, button: classes.button}}
                            key={program.id + "_" + i + "_navListItem"}
                            my="2rem"
                            >
                              <ListItemText primary={program.info["Name"]} disableTypography/>
                              <ListItemText primary={program.userPoints.toLocaleString()} align='right' disableTypography/>
                          </ListItem>);
        }
      } 
     return programList;
    }

    createHelpText(title) {
        let newHelpText = "The JETALBERT Points Inspiration Tool is designed to maximize your points journey. Update your points balance to generate a variety of best value Awards curated by our proprietary algorithm, which evaluates program ease, product, and rates. To maximize value, we recommend staying flexible when booking travel as rates vary dynamically by route, city, date, and demand. Finish and save a copy of your completed dashboard to your Desktop by using the download button. For more points help, visit ";
        // let newHelpText = "Cannot find a tip for this Card.";
        // This is now no longer card specific but if that changes the ladder below allows customization
        // if (this.props.title == "Credit Card Points Overview") {
        //   newHelpText = "Using the Points Inspiration Tool is simple. Here in the Credit Card Points Overview section, you can freely change your program balance to see a variety of curated Air and Hotel Award redemptions. Once finished, download a copy of your completed dashboard by using the download button. These options are meant for reference as exact points required may vary by program. It's always best to check with your program for updates.";
        // } else if (this.props.title == "Airline Miles Overview") {
        //   newHelpText = "Using the Points Inspiration Tool is simple. Here in the Airline Miles Overview section, you can freely change your program balance to see a variety of curated Air Award redemptions. Once finished, download a copy of your completed dashboard by using the download button. These options are meant for reference as exact points required may vary by program. It's always best to check with your program for updates.";
        // } else if (this.props.title == "Hotel Points Overview") {
        //   newHelpText = "Using the Points Inspiration Tool is simple. Here in the Hotel Points Overview section, you can freely change your program balance to see a variety of curated Hotel Award redemptions. Once finished, download a copy of your completed dashboard by using the download button. These options are meant for reference as exact points required may vary by program. It's always best to check with your program for updates.";
        // }
        return newHelpText;
    }
 
    // TODO this class is bloated and could use a refactor
    render() {
        const { classes } = this.props;
        return (
            <Card elevation={0} className={classes.topOfTree}>
                <PointsEditModal 
                  rewardPrograms={this.state.programs} 
                  onProgramChange={(e, programs) => this.handleProgramChange(e, programs)}
                  onModalClose={(e) => this.handleModalClose(e)}
                  openModal={this.state.openModal}
                />
                  <Popper open={this.state.openTooltip} anchorEl={this.state.tooltipAnchor} placement='top' transition>
                    {({ TransitionProps }) => (
                        <ClickAwayListener onClickAway={this.handleHelpClickAway.bind(this)}>
                          <Fade {...TransitionProps} timeout={350}>
                              <Box bgcolor="primary.main" p={2} className={classes.helpBox}>
                                <Typography color="textSecondary" variant="body2">{this.createHelpText(this.props.title)} <Link color="secondary" href="https://www.jetalbert.com/pointsconcierge">jetalbert.com/pointsconcierge</Link>.</Typography>  
                              </Box>
                          </Fade>
                        </ClickAwayListener>
                    )}
                  </Popper>
              <CardHeader
                  action={
                    <CardActions>
                      <IconButton aria-label="help"
                                  onClick={this.handleHelpClick.bind(this)}
                      >
                        <HelpOutlineIcon />
                      </IconButton>
                      <IconButton aria-label="export"
                                  disableRipple
                                  className={classes.exportButton}
                                  onClick={this.handleExportClick.bind(this)}
                      >
                        <SystemUpdateAltIcon />
                      </IconButton>
                      <IconButton aria-label="edit"
                                  onClick={this.handleEditClick.bind(this)}
                      >
                        <BorderColorIcon />
                      </IconButton>
                    </CardActions>
                  }
                  title={this.props.title}
                  titleTypographyProps={{
                      variant:'h6'
                  }}
                  className={classes.header}/>
              <Divider />
              <CardContent style={{padding:'5px 0px'}}>
                  <Grid container>
                      <Grid item sm={4} xs={12}>
                          <List component="nav" style={{padding:"10px 0px"}}>
                             {this.createProgramListItems(classes)}
                          </List>
                      </Grid>
                    <Grid item sm={8} xs={12 }>
                        <Collapse in={this.state.expanded}
                                    collapsedHeight='265px'
                        >
                            <FirebaseContext.Consumer>
                            {firebase => <OfferProgressContainer  firebase={firebase}
                                                                  userInfo={this.props.userInfo}
                                                                  dataId={this.props.dataId}
                                                                  programs={this.state.programs}
                                                                  selected={this.state.selectedIndex}
                                                                  />
                                }
                            </FirebaseContext.Consumer>
                        </Collapse>
                    </Grid>
                  </Grid>
              </CardContent>
              <CardActions className="floatRight">
                <Button size="small"
                        onClick={(e) => this.handleExpandClick(e)}
                        className={classes.expandButton}
                >
                    {this.state.expanded ? "View Less" : "View More"}
                </Button>
              </CardActions>
            </Card>
          );
    }
}
export default withStyles(styles)(PointsCard);