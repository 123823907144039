import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import {IconButton, Container, Box, Grid, Typography, TextField, Link, Divider, Button} from '@material-ui/core/';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

const styles = theme => ({
    root: {
        color: "red"
    },
    textField: {
        color: 'rgba(255, 255, 255, 0.49)'
    },
    linksContainer: {
        "& .MuiGrid-item": {
            paddingLeft:15
        }
    },
    socialLinks: {
    }
});

class Footer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            email: ""
        }
    }

    render() {
        const { classes } = this.props;
        return(
            <Box bgcolor="primary.main" color="text.secondary" pb={8}>
                <Container maxWidth='md'>
                    <Box mb={16} pt={8}>
                        <Grid container spacing={6}>
                            <Grid item md={2} className={classes.socialLinks}>
                                <Typography variant="body1" align='center' style={{fontFamily: "raleway", letterSpacing: "5px", fontWeight: "800", paddingLeft:15}} color="textSecondary">
                                        JETALBERT
                                </Typography>
                                <Grid container>
                                    <Grid item md={4}>
                                        <IconButton aria-label="help" color="secondary" href="https://www.instagram.com/jetalbert/">
                                            <InstagramIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item md={4}>
                                        <IconButton aria-label="help" color="secondary" href="https://www.facebook.com/jetalbertblog">
                                            <FacebookIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item md={4}>
                                        <IconButton aria-label="help" color="secondary" href="https://twitter.com/jetalbert">
                                            <TwitterIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={5}>
                                <Grid container className={classes.linksContainer}>
                                    <Grid item sm={6} xs={12}>
                                        <Box my={1}>
                                            <Link href="?reload=1" color="textSecondary">Points Inspiration Tool</Link>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Box my={1}>
                                            <Link href="https://jetalbert.com/blog" color="textSecondary">Blog</Link>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Box my={1}>
                                            <Link href="https://jetalbert.com/pointsconcierge" color="textSecondary">Points Conceirge Services</Link>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Box my={1}>
                                            <Link href="https://jetalbert.com/privacypolicy" color="textSecondary">Privacy Policy</Link>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Box my={1}>
                                            <Link href="https://jetalbert.com/partnerships" color="textSecondary">Partnerships &amp; Advertising</Link>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Box my={1}>
                                            <Link href="https://jetalbert.com/terms" color="textSecondary">Terms of Service</Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={5}>
                                <Typography variant="body2">Stay connected and sign-up for exciting aviation, travel, and points inspiration direct to your inbox.</Typography>
                                    <form action="https://jetalbert.us10.list-manage.com/subscribe/post" method="POST">
                                        <input type="hidden" name="u" value="8031e11b8550cb4ae3198ea48"/>
                                        <input type="hidden" name="id" value="cc17a2daca"/>
                                        <Box display="flex" flexDirection="row" alignItems="left" justifyContent="space-between">
                                        <TextField placeholder="EMAIL ADDRESS" 
                                                    InputProps={{
                                                            className: classes.textField,
                                                            disableUnderline: true
                                                        }}
                                                    color="secondary"
                                                    key={"TBD"}
                                                    type="email" 
                                                    name="EMAIL" 
                                                    id="MERGE0"
                                                    onChange={ (e)=>{this.setState({email: e.target.value});} } 
                                        />
                                        <Button color="secondary"
                                                style={{alignSelf:'right'}}
                                                type="submit"
                                                disableElevation>SUBSCRIBE ⟶</Button>
                                        </Box>
                                    </form>
                                <Divider light/>
                            </Grid>
                        </Grid>
                    </Box>
                    <hr
                        style={{
                            color: 'grey',
                            backgroundColor: 'grey',
                            height: .5,
                            borderColor:'grey'
                        }}
                    />
                    <Box display="flex" justifyContent="space-between">
                        <Typography component="div" variant="body2">BUILT BY <Link href="https://huntercodes.com" color="secondary"><b>HUNTERCODES</b></Link></Typography>
                        <Typography component="div" variant="body2">&copy; JETALBERT LLC 2020</Typography>
                    </Box>
                </Container>
            </Box>
        );
    }
}


export default  withStyles(styles)(Footer);