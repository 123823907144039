import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import {Box, Container, Grid, Typography} from '@material-ui/core/';

const styles = theme => ({
    greyBox: {
        backgroundColor:'#ebeadf',
    },
    offsetContainer: {
        paddingLeft:64,
        paddingRight:0,
        overflow: 'hidden',
        position:'relative',
        [theme.breakpoints.down('sm')]: {
            padding:0
        }
    },
    overlayImg: {
        zIndex:10,
        position:'absolute',
        top:'25%',
        [theme.breakpoints.down('sm')]: {
            top:37,
            left:25
        }
    },
    rotateTip: {
        width: 78,
        height: 20,
        transform:'rotate(-90deg)',
        textTransform:'uppercase',
        fontFamily:'Lato',
        fontSize: 12,
        fontStretch:'normal',
        fontStyle:'normal',
        lineHeight: 1.67,
        letterSpacing: 1,
        display:'inline-block',
        position:'absolute',
        marginTop:20
    },
    bigNumber: {
        textAlign: 'right',
        width: 78,
        fontFamily: 'Lato',
        fontSize: 36,
        fontWeight: 400,
        left: 60,
        top: 25,
        position:'relative'
    },
    iconFlare: {
        [theme.breakpoints.down('sm')]: {
            height: 130
        }
    },
    counterContainer: {
        [theme.breakpoints.down('sm')]: {
            height: 130,
            marginLeft: 72,
            marginTop: 25,
        }
    },
    tipContentHeader: {
        // textAlign: 'justify',
        [theme.breakpoints.down('sm')]: {
           padding: 20
        }
    },
    tipContentBody: {
        textAlign: 'justify',
        [theme.breakpoints.down('sm')]: {
           padding: "0px 20px 40px"

        }
    }
});

class TipSection extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Box style={{position:'relative'}}>
                <img src="images/plane.svg" alt='' className={classes.overlayImg}></img>
                <Container className={classes.offsetContainer}>
                    <Box py={2} className={classes.greyBox} style={{minHeight:140}}>
                        <Grid container justify="center" alignItems="center">
                            <Grid item md={1} className={classes.iconFlare}>
                                <Box>
                                    <div style={{position:'relative'}} className={classes.counterContainer}>
                                        <span className={classes.rotateTip}>Insider Tip</span>
                                        <span className={classes.bigNumber}>1</span>
                                    </div>
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box px={5} className={classes.tipContentHeader}>
                                    <Typography variant="h6">Maximize your Chase points value by transferring them to one of Chase’s many airline partners and redeem flights directly.</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={4}>
                                <Box px={5} className={classes.tipContentBody}>
                                    <Typography variant="body2">Chase airline partners include United, British Airways, Singapore Airlines, and more, Reminder points cannot be returned back to your account after the points have left.</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        );
    }
}

export default  withStyles(styles)(TipSection);