const cabinClassConverter = {
    "Economy Class": 1,
    "Premium Economy": 2,
    "Business Class": 3,
    "First Class": 4,
    "Suites": 5
}

const lowPointCutoff = 50000;
const midPointCutoff = 100000;
const lowPointCutoffHotels = 25000;
const midPointCutoffHotels = 50000;
const numSlots = 8;

function compareAirOffers(offer_a, offer_b) {
    const combinedScoreA = cabinClassConverter[offer_a["Product"]] * 10000 + offer_a["Score"]*100;
    const combinedScoreB = cabinClassConverter[offer_b["Product"]] * 10000 + offer_b["Score"]*100;
    if (combinedScoreA !== combinedScoreB) {
        return combinedScoreB - combinedScoreA;
    } else {
        // Tiebreaker comes down to pointsNeeded least points is best
        return offer_a["Points Needed"] - offer_b["Points Needed"];
    }
}

function compareHotelOffers(offer_a, offer_b) {
    const combinedScoreA = offer_a["Score"]*100;
    const combinedScoreB = offer_b["Score"]*100;
    if (combinedScoreA !== combinedScoreB) {
        return combinedScoreB - combinedScoreA;
    } else {
        // Tiebreaker comes down to pointsNeeded least points is best
        return offer_a["Points Needed"] - offer_b["Points Needed"];
    }
}

/**
 * Given an array of offers and array of the remaining offers returns a new array
 * of offers which 'backfills' redemptions in the user's price range
 * @param {Array} slottedOffers 
 * @param {Array} remainingOffers 
 * @param {number} maxPointsFilter 
 */
function backfillOffers(slottedOffers, remainingOffers, maxPointsFilter) {
    let backfilledOffers = slottedOffers.filter((offer) => offer != null);
    if (backfilledOffers.length < numSlots) {
        // If we are at less than 8 offers attempt a backfill of
        // remaining slots with offers in the user's points range
        let affortableRemainingOffers = remainingOffers.filter( offer => {
            return offer["Points Needed"] <= maxPointsFilter;
        });
        const numBackfill = numSlots - backfilledOffers.length;
        for (let i = 0; i < numBackfill; i++) {
            backfilledOffers.push(affortableRemainingOffers.shift());
        }
    }
    return backfilledOffers;
}

class RedemptionEvaluator {
    /**
     * Orders and returns Credit-Card redemption offers based off of scoring algorithm
     */
    static filterAndRankCCardOffers(availableOffers, userPoints) {
        let sortedOffers = availableOffers.slice().sort(compareAirOffers);
        let rankedOffers = [null, null, null, null, null, null, null, null]; // Each slot defaults to null
        const pointsBucketMax = userPoints <= lowPointCutoff ? lowPointCutoff : userPoints <= midPointCutoff ? midPointCutoff: 1000000;

        for(var i=0; i < numSlots; i+=1) {
            if (i===0 || i===1) {
                // 1-2: MAX class of travel, MIN points, MAX score
                rankedOffers[i] = sortedOffers.shift();    
            } else if (i===2) {
                // 3: Same as above 1 range out of user's point range
                if (pointsBucketMax===lowPointCutoff) {
                    // Use mid range 50k - 100k
                    const midRangeOffersIndicies = sortedOffers.reduce(function(acc, offer, i) {
                        if (offer["Points Needed"] > lowPointCutoff && offer["Points Needed"] < midPointCutoff)
                            acc.push(i);
                        return acc;
                    }, []);
                    const matchIndex = midRangeOffersIndicies.shift();
                    if (matchIndex !== undefined) {
                        rankedOffers[i] = sortedOffers[matchIndex];
                        sortedOffers.splice(matchIndex, 1);
                    }
                } else {
                    // Over 100k range
                    const highPointsOffersIndicies = sortedOffers.reduce(function(acc, offer, i) {
                        if (offer["Points Needed"] > midPointCutoff)
                            acc.push(i);
                        return acc;
                    }, []);
                    const matchIndex = highPointsOffersIndicies.shift();
                    if (matchIndex !== undefined) {
                        rankedOffers[i] = sortedOffers[matchIndex];
                        sortedOffers.splice(matchIndex, 1);
                    }
                }
            } else if (i===3 || i===4) {
                // 4-5: Same as 1/2 with range in user's points range
                const inRangeOffersIndicies = sortedOffers.reduce((acc, offer, i) => {
                    if (offer["Points Needed"] <= pointsBucketMax) {
                        acc.push(i)
                    }
                    return acc;
                }, []);
                const matchIndex = inRangeOffersIndicies.shift();
                if (matchIndex !== undefined) {
                    rankedOffers[i] = sortedOffers[matchIndex];
                    sortedOffers.splice(matchIndex, 1);
                }
            } else if (i===5 || i===6) {
                // 6-7: Hotels, currently undefined in db
                rankedOffers[i] = null;
            } else {
                // 8: Special case cashback points
                // TODO implement this, for now always null
                rankedOffers[i] = null;
            }
        }
        rankedOffers = backfillOffers(rankedOffers, sortedOffers, pointsBucketMax);
        return rankedOffers;
    }

    /**
     * Orders and returns Airline redemption offers based off scoring algorithm
     */
    static filterAndRankAirOffers(availableOffers, userPoints) {
        const sortedOffers = availableOffers.slice().sort(compareAirOffers);
        let rankedOffers = [null, null, null, null, null, null, null, null]; // Each slot defaults to null
        const pointsBucketMax = userPoints <= lowPointCutoff ? lowPointCutoff : userPoints <= midPointCutoff ? midPointCutoff: 1000000;
        for(var i=0; i < numSlots; i+=1) {
            if (i===0 || i===1) {
                // 1-2: MAX class of travel, MIN points, MAX score
                rankedOffers[i] = sortedOffers.shift();    
            } else if (i===2 || i===3) {
                // 3-4: Same as above 1 range out of user's point range
                if (pointsBucketMax === lowPointCutoff) {
                    // Show offer from mid-range
                    const midRangeOffersIndicies = sortedOffers
                                                    .map((offer, i) => {
                                                        if (offer["Points Needed"] > lowPointCutoff && offer["Points Needed"] <= midPointCutoff) {
                                                            return i;
                                                        } else {
                                                            return -1;
                                                        }
                                                    }).filter(index => index !== -1);
                    const matchIndex = midRangeOffersIndicies.shift();
                    if (matchIndex !== undefined) {
                        rankedOffers[i] = sortedOffers[matchIndex];
                        sortedOffers.splice(matchIndex, 1);
                    }
                } else {
                    // Show offer from high range
                    const highRangeOfferIndicies = sortedOffers
                                                    .map((offer, i) => {
                                                        if (offer["Points Needed"] > midPointCutoff) {
                                                            return i;
                                                        } else {
                                                            return -1;
                                                        }
                                                    }).filter(index => index !== -1);
                    const matchIndex = highRangeOfferIndicies.shift();
                    if (matchIndex !== undefined) {
                        rankedOffers[i] = sortedOffers[matchIndex];
                        sortedOffers.splice(matchIndex, 1);
                    }
                }
            } else {
                // 5-8: Same as 1/2 with range in user's points range
                const inRangeOffersIndicies = sortedOffers
                                                .map((offer, i) => {
                                                    if (offer["Points Needed"] < pointsBucketMax) {
                                                        return i;
                                                    } else {
                                                        return -1;
                                                    }
                                                }).filter(index => index !== -1);
                const matchIndex = inRangeOffersIndicies.shift();
                if (matchIndex !== undefined) {
                    rankedOffers[i] = sortedOffers[matchIndex];
                    sortedOffers.splice(matchIndex, 1);
                }
            }
        }
        rankedOffers = backfillOffers(rankedOffers, sortedOffers, userPoints);
        return rankedOffers;
    }

    /**
     * Orders and returns Hotel redemption offers based off of scoring algorithm
     * 
     * Interestingly this turned out ot be the same as the airlineSort expect for the compare function.
     */
    static filterAndRankHotelOffers(availableOffers, userPoints) {
        const sortedOffers = availableOffers.slice().sort(compareHotelOffers);
        let rankedOffers = [null, null, null, null, null, null, null, null]; // Each slot defaults to null

        // Low point for hotel is 25k
        // Mid point is 50k
        const pointsBucketMax = userPoints <= lowPointCutoffHotels ? lowPointCutoffHotels : userPoints <= midPointCutoffHotels ? midPointCutoffHotels: 1000000;
        for(var i=0; i < numSlots; i+=1) {
            if (i===0 || i===1) {
                // 1-2: Max stars in Region for minimum points required
                rankedOffers[i] = sortedOffers.shift();    
            } else if (i===2 || i===3) {
                // 3-4: Same as 1-2 but one range out of reach
                if (pointsBucketMax === lowPointCutoffHotels) {
                    // Show offer from mid-range
                    const midRangeOffersIndicies = sortedOffers
                                                    .map((offer, i) => {
                                                        if (offer["Points Needed"] > lowPointCutoffHotels && offer["Points Needed"] <= midPointCutoffHotels) {
                                                            return i;
                                                        } else {
                                                            return -1;
                                                        }
                                                    }).filter(index => index !== -1);
                    const matchIndex = midRangeOffersIndicies.shift();
                    if (matchIndex !== undefined) {
                        rankedOffers[i] = sortedOffers[matchIndex];
                        sortedOffers.splice(matchIndex, 1);
                    }
                } else {
                    // Show offer from high range
                    const highRangeOfferIndicies = sortedOffers
                                                    .map((offer, i) => {
                                                        if (offer["Points Needed"] > midPointCutoffHotels) {
                                                            return i;
                                                        } else {
                                                            return -1;
                                                        }
                                                    }).filter(index => index !== -1);
                    const matchIndex = highRangeOfferIndicies.shift();
                    if (matchIndex !== undefined) {
                        rankedOffers[i] = sortedOffers[matchIndex];
                        sortedOffers.splice(matchIndex, 1);
                    }
                }
            } else {
                // 5-8: Same as 1-2 but in range
                const inRangeOffersIndicies = sortedOffers
                                                .map((offer, i) => {
                                                    if (offer["Points Needed"] < pointsBucketMax) {
                                                        return i;
                                                    } else {
                                                        return -1;
                                                    }
                                                }).filter(index => index !== -1);
                const matchIndex = inRangeOffersIndicies.shift();
                if (matchIndex !== undefined) {
                    rankedOffers[i] = sortedOffers[matchIndex];
                    sortedOffers.splice(matchIndex, 1);
                }
            }
        }
        rankedOffers = backfillOffers(rankedOffers, sortedOffers, userPoints);
        return rankedOffers;
    }
}

export default RedemptionEvaluator;