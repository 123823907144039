import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Modal, Box, Paper, Grid, Typography} from '@material-ui/core/';
import {Button, Fade} from '@material-ui/core';
import ReactGA from 'react-ga';
 
const styles = theme => ({
    roundedButton: {
        marginTop: 15,
        borderRadius: 25,
        height: 35,
        letterSpacing: 0.83,
        fontSize: 10,
    },
    intersitialRoot: {
        position: 'absolute',
        top: '25%',
        left: '28%',
        width: '44%',
        minHeight:360,
        maxHeight: '80%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        outline: 0,
        [theme.breakpoints.down('xs')]: {
            left: 16,
            width: '90%',
            top: 50,
            height:420
        }
    },
    gridImgSection: {
        height:'100%',
        backgroundImage: `url(${"images/interstitial-img@3x.png"})`,
        backgroundSize:'cover',
        backgroundPosition: 'top',
        [theme.breakpoints.down('xs')]: {
            minHeight: 156,
            height:'100%'
        }
    }
});

function buildURLFromBooking(bookWithName) {
    //e.g. 'https://bit.ly/jetalbertaeromexico'
    const linkPrefix = 'https://bit.ly/jetalbert'
    const bookWithExt = bookWithName.toLowerCase().replace(/ /g, '');
    return linkPrefix + bookWithExt;
}
 
class BookNowButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false
        }
    }

    render() {
        const { classes } = this.props;
        const extLink = buildURLFromBooking(this.props.bookWith);
        const bookWithGALabel = "Book with: " + this.props.bookWith;
        return (
            <div>
                <Modal
                    open={this.state.openModal}
                    onClose={(e) => {this.setState({openModal: false})}}
                >
                    <Paper className={classes.intersitialRoot}>
                        <Grid container style={{height:'inherit', minHeight:360}}>
                            <Grid item sm={5} xs={12}>
                                <Box className={classes.gridImgSection}></Box>
                            </Grid>
                            <Grid item sm={7} xs={12}>
                                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{height:'100%'}}>
                                        <img className={classes.deskOnly} src="images/safe-travels.svg" alt='' style={{width:80, height:30, marginTop:25}}></img>
                                        <Box textAlign="center" p={2}>
                                            <Typography variant="h5" style={{paddingBottom:8}}>Nice Work! Let's continue your points journey</Typography>
                                            <Typography variant="body1" style={{paddingBottom:8}}>Ready to search award availability with <br/><b>{this.props.bookWith}?</b></Typography>
                                            <Button className={classes.roundedButton}  color='primary' variant='contained' style={{display:"block", width:156, margin:'0 auto 8px'}} disableElevation>
                                                <ReactGA.OutboundLink
                                                    eventLabel={bookWithGALabel}
                                                    to={extLink}
                                                    target="_blank"
                                                    style={{color:"white", textDecoration:'none'}}
                                                >
                                                    Let's Go
                                                </ReactGA.OutboundLink>
                                            </Button>
                                            <Button className={classes.roundedButton} color="primary" variant="outlined" style={{display:"block", width:156, margin:'0 auto 8px', paddingTop:8}} disableElevation href="https://jetalbert.com/pointsconcierge">More Points Help</Button>
                                        </Box>
                                    </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Modal>
                <Fade in={this.props.visible} timeout={350}>
                    <Button
                        color='primary'
                        variant='contained'
                        disableElevation
                        onClick={(e) => {this.setState({openModal: true})}}
                        className={classes.roundedButton}
                        style={{width: 100}}
                        >
                            Let's Go
                    </Button>
                </Fade>
            </div>
        );
    }
}
export default  withStyles(styles)(BookNowButton);