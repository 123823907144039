import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Box, Container, Grid, Typography, Button} from '@material-ui/core/';
import {createClient} from "contentful";    
 
const styles = theme => ({
    adBox: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height:180,
        [theme.breakpoints.down('sm')]: {
            height:320,
            flexDirection:'column',
            alignItems: 'center',
            justifyContent: 'space-around'
        }
    },
    roundedButton: {
        borderRadius: 25,
        width: 140,
        height: 35,
        letterSpacing: 0.83,
        fontSize: 10
    },
    logoCaption: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 55       
        }
    }
});
 
class AdSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brandLogoUrl: "images/jetalbert_logo_img.png",
            brandTagline: "Follow @jetalbert on Instagram for more travel content.",
            buttonText: "Let's Go!",
            buttonLink: "https://www.instagram.com/jetalbert/",
            backgroundImage: "images/ad_background.jpg",
        }
        this.contentfulClient = createClient({
                                accessToken:'BqbJ6qTW1C2hUzzjN2hOzWh4JWGjZl9IVUws39BXyZs',
                                space:'gsutkxukxirj'
                            });
    }


    componentDidMount() {
        this.contentfulClient.getEntries({content_type:"lowerAd"})
            .then(response => {
                const adObj = response.items[0].fields;
                let newState = this.state;
                newState.brandTagline = adObj.brandTagline;
                newState.buttonText = adObj.buttonText;
                newState.buttonLink = adObj.buttonLink;
                newState.brandLogoUrl = adObj.brandLogo.fields.file.url;
                newState.backgroundImage = adObj.backgroundImage.fields.file.url
                this.setState(newState);
            })
    }
    render() {
        const { classes } = this.props;
        return (
            <Box>
                <Grid container justify="center" alignItems="center" className={classes.adBox} style={{backgroundImage: `url("` + this.state.backgroundImage + `")`}}>
                    <Grid item md={5}>
                        <Box className={classes.logoCaption}>
                            <Container>
                                <img src={this.state.brandLogoUrl} alt='Brand Logo' style={{width:200, height:37}}></img>
                            </Container>
                            <Container>
                                <Typography variant="h6">{this.state.brandTagline}</Typography>
                            </Container>
                        </Box>
                    </Grid>
                    <Grid item md={5} sm={false}></Grid>
                    <Grid item md={2}>
                        <Button
                            color='primary'
                            variant='contained'
                            disableElevation
                            href={this.state.buttonLink}
                            className={classes.roundedButton}
                        >
                            {this.state.buttonText}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}
export default  withStyles(styles)(AdSection);