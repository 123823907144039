import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import {Paper, Container, Box, Grid} from '@material-ui/core/'
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';

import {createClient} from 'contentful';

const styles = theme => ({
    root: {
      maxWidth: 345,
    },
    paper : {
        backgroundImage: `url(${"images/header-bg@3x.png"})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#8B9EA7',
        backgroundSize: 'cover',
        backgroundPosition: 'left',
        width:"100%",
        height: '400px',
        [theme.breakpoints.down('xs')]: {
            backgroundImage: `url(${"images/header-bg-mobile@3x.png"})`,
            backgroundPosition: 'top',
            height:500
        }
    },
    ttu: {
        textTransform: "uppercase",
        letterSpacing: "1px"
    },
    media: {
      height: 140,
    },
    heroText: {
        [theme.breakpoints.down('xs')]: {
            padding:20
        }
    },
    welcomeText: {
        minHeight: 200,
        // marginTop:
        [theme.breakpoints.down('xs')]: {
            marginTop:150,
            minHeight: 200,
        }
    }
});  



class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            headerText: "Welcome aboard.",
            welcomeText: "Designed by frequent flyers for all flyers, jetalbert.com is a new digital platform to help you understand the true value of your points. You now have the travel tools to dream, become inspired, and prepare for your next points trip.",
        };
        this.client = createClient( {
            accessToken:'BqbJ6qTW1C2hUzzjN2hOzWh4JWGjZl9IVUws39BXyZs',
            space:'gsutkxukxirj'
          })   
    }

    componentDidMount() {
        // Testing contentful API
        this.client.getEntries({content_type: 'hero'})
          .then(response => {
            const heroObj = response.items[0].fields;
            let newState = this.state;
            newState.headerText = heroObj["mainHeader"];
            newState.welcomeText = heroObj["heroIntroText"];
            this.setState(newState);
        });
    }

    render() {
        const { classes } = this.props;
    
        const handleDrawerOpen = () => {
            console.log("We're doing it!");
            this.setState({menuOpen:true});
        }
        
        const toggleDrawer = (currentState) => (event) => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            this.setState({menuOpen:!currentState});
        }
        return(
            <div>
            <AppBar position="static" style={{alignItems: 'center'}} elevation={0}>
                <Paper className={classes.paper} square elevation={0}>
                    <Toolbar>
                        <Box alignItems="center" justifyContent="center" style={{flex: 1}} mt={4}>    
                                <Typography variant="h5" component="h1" align='center' style={{fontFamily: "raleway", letterSpacing: "5px", fontWeight: "800"}} color="textSecondary">
                                    JETALBERT | BETA
                                </Typography>
                                <Typography variant="subtitle1" component="h1" align='center' className={classes.ttu} color="textSecondary">
                                    Points Inspiration Tool™
                                </Typography>
                        </Box>
                        <IconButton
                            color="secondary"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                    <Drawer
                            anchor='top'
                            open={this.state.menuOpen}
                            onClose={toggleDrawer(this.state.menuOpen)}
                    >
                        <Box m={5}>
                            <List>
                                <ListItem button component="a" href="https://jetalbert.com" style={{padding:[0, 32], textAlign:"right"}}>
                                    <ListItemText primary="Home"/>
                                </ListItem>
                                <ListItem button component="a" href="https://jetalbert.com/blog" style={{padding:[0, 32], textAlign:"right"}}>
                                    <ListItemText primary="Blog"/>
                                </ListItem>
                                <ListItem button component="a" href="https://jetalbert.com/pointsconcierge" style={{padding:[0, 32], textAlign:"right"}}>
                                    <ListItemText primary="Points Conceirge Services"/>
                                </ListItem>
                                <ListItem button component="a" href="?reload=1" style={{padding:[0, 32], textAlign:"right"}}>
                                    <ListItemText primary="Points Inspiration Tool"/>
                                </ListItem>
                                <ListItem button component="a" href="https://jetalbert.com/partnerships" style={{padding:[0, 32], textAlign:"right"}}>
                                    <ListItemText primary="Partnerships &amp; Advertising"/>
                                </ListItem>
                                <ListItem button component="a" href="https://jetalbert.com/terms" style={{padding:[0, 32], textAlign:"right"}}>
                                    <ListItemText primary="Terms of Service"/>
                                </ListItem>
                                <ListItem button component="a" href="https://jetalbert.com/privacypolicy" style={{padding:[0, 32], textAlign:"right"}}>
                                    <ListItemText primary="Privacy policy"/>
                                </ListItem>
                            </List>
                        </Box>
                    </Drawer>
                    <Container maxWidth="md" className={classes.heroText}>
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            >
                                <Grid item sm={6} xs={false}>
                                </Grid>
                                <Grid item sm={6} xs={12}>    
                                    <Box alignItems="left" className={classes.welcomeText} justifyContent="space-between" display="flex" flexDirection="column" mt={6}>   
                                            <Typography variant="h3" color="textSecondary">
                                                {this.state.headerText}
                                            </Typography>
                                            <Box>
                                                <Typography variant="subtitle1" component="p" color="textSecondary" style={{paddingBottom:5}}>
                                                {this.state.welcomeText}
                                                </Typography>
                                                <Link href="#terms" color="textSecondary">
                                                    <Typography variant="overline" display="block" gutterBottom>
                                                        Terms and Conditions
                                                    </Typography>
                                                </Link>
                                            </Box>
                                    </Box>
                                </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </AppBar>
            </div>
        )

    }

}
export default withStyles(styles)(NavBar);