import React from 'react'
import {Box, Typography} from '@material-ui/core'

const PointTotalCircle = (props) => {
            var pointText = props.pointAmount.toLocaleString();
            if (props.isDollar) {
                pointText = '$' + pointText;
            }
            return(
            <Box display="flex"
                flexDirection="column"
                alignItems='center'
                justifyContent='center'
                bgcolor="primary.main"
                style={{borderRadius:'50%', width:"125px", height:"125px"}}
            >        
                <Typography variant="h6" color="textSecondary">{pointText}</Typography>
                <Typography variant="body2" color="textSecondary" style={{fontFamily:"TamilMN", textTransform:"uppercase", fontSize:10}}>{props.line1}</Typography>
                <Typography variant="body2" color="textSecondary" style={{fontFamily:"TamilMN", textTransform:"uppercase", fontSize:10}}>{props.line2}</Typography>
            </Box>
        );
}
export default PointTotalCircle;