import React from 'react';
import './App.css';

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import Footer from './components/Footer';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import NavBar from './components/NavBar'
import SearchQuestions from './components/SearchQuestions'
import PointsCard from './components/PointsCard';
import PointsTotalCard from './components/PointsTotalCard';
import TipSection from './components/TipSection';
import EmailPoints from './components/EmailPoints';
import AdSection from './components/AdSection'
import { FirebaseContext } from './components/Firebase';

import ReactGA from 'react-ga';

let theme = createMuiTheme({
  typography: {
    fontFamily: 'TamilMN, Arial',
    fontSize: 12,
    body1: {
      fontFamily: 'Lato',
      fontWeight: 300
    },
    body2: {
      fontFamily: 'Lato',
      fontWeight: 300,
      fontSize: '.75rem'
    },
    subtitle2: {
      fontFamily: 'Lato',
      fontWeight: 300,
      color: '#4c4c4c'
    },
    subtitle1: {
      fontFamily: 'Lato',
    },
    button: {
      fontFamily: 'Lato'
    },
    caption: {
      fontFamily: 'Lato',
    },
    overline: {
      fontFamily: 'Lato',
    }
  },
  palette: {
    primary: {
      main: '#333333'
    },
    secondary: {
      main: '#ffffff'
    },
    text: {
      primary: '#333333',
      secondary: '#ffffff'
    },
    background: {
      default: "#fffef3",
      paper: "#fffef3"
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#333333',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#000000',
      },
      light: {
        backgroundColor: '#ffffff',
      }
    }
  },
});
theme = responsiveFontSizes(theme);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        user : {
                "credit-card":0,
                "airlines":0,
                "hotels":0,
                "goalArrival":null,
                "goalDeparture":null,
              }
            }
    ReactGA.initialize('UA-173478663-1', {debug: false});
    // This just needs to be called once since we have no routes in this case.
    ReactGA.pageview(window.location.pathname);
  }

  handleUserInfoUpdate(e, programInfo, programId) {
    if(programInfo.length === 0) {
        // console.log("Cannot reduce an empty array");
        return;
    }
    var totalPoints = programInfo.map(program => program.userPoints).reduce((prev, next) => prev + next);
    const newState = this.state;
    newState.user[programId] = totalPoints;
    this.setState(newState);
  }

  handleUserPreferenceUpdate(e, arrival, departure) {
    let newState = this.state;
    if (arrival !== undefined) {
      newState.user['goalArrival'] = arrival;
    }
    if (departure !== undefined) {
      newState.user['goalDeparture'] = departure
    }
    this.setState(newState);
  }
  
  render() {
    
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App" id="screenshotSection">
          <div>
            <NavBar />
          </div>
          <section className="calculatorSection">
            <Container maxWidth="md">
              <Box>
                <SearchQuestions userInfo={this.state.user}
                                  onUserPreferenceUpdate={(e, arrival, departure) => this.handleUserPreferenceUpdate(e, arrival, departure)}/>
              </Box>
              <section>
                <Box mt={10}>
                  <PointsTotalCard userInfo={this.state.user} />
                </Box>
                <Box mt={5}>
                  <FirebaseContext.Consumer>
                    {firebase =>  <PointsCard userInfo={this.state.user} 
                                              onUserInfoUpdate={(e, programInfo, programId) => this.handleUserInfoUpdate(e, programInfo, programId)} 
                                              dataId="credit-card"
                                              title="Your Credit Card Points"
                                              firebase={firebase}
                                  />
                        }
                  </FirebaseContext.Consumer>
                </Box>
                <Box mt={5}>
                    <TipSection/>
                </Box>
                <Box mt={5}>
                <FirebaseContext.Consumer>
                    {firebase =>  <PointsCard userInfo={this.state.user} 
                                              onUserInfoUpdate={(e, programInfo, programId) => this.handleUserInfoUpdate(e, programInfo, programId)} 
                                              dataId="airlines"
                                              title="Your Airline Miles"
                                              firebase={firebase}
                                  />
                        }
                  </FirebaseContext.Consumer>
                </Box>
                <Box mt={5} mb={5}>
                <FirebaseContext.Consumer>
                    {firebase =>  <PointsCard userInfo={this.state.user} 
                                              onUserInfoUpdate={(e, programInfo, programId) => this.handleUserInfoUpdate(e, programInfo, programId)} 
                                              dataId="hotels"
                                              title="Your Hotel Points"
                                              firebase={firebase}
                                  />
                        }
                  </FirebaseContext.Consumer>
                </Box>
                <Divider />
              </section>
              </Container>
          </section>
          <section className="prefooter">
            <Container maxWidth="md">
              <Box mb={5} mt={4}>
                <EmailPoints/>
              </Box>
              <Container>
                <Box my={8} id="terms" style={{textAlign:"justify"}}>
                  <Typography style={{color:'#3a3a3a', fontWeight:300, lineHeight:1.4}} variant='caption'>*This site is designed for US based travelers and credit card holders as a reference only; we hope to introduce a global expansion of our tool in the future. Information listed are opinions and recommendations of JETALBERT, LLC, and based on highest value, simplicity, and proven experience. However, we understand there are many ways of redeeming the same trip and flight, with some options showing fewer points. These options here are consistently considered the easiest to accomplish. While we will do our absolute best to maintain current point redemption amounts, many loyalty programs have introduced dynamic and variable pricing which means amounts can change at any point. Please visit the respective airline or hotel program for the latest information on exact redemption options. JETALBERT, LLC is not an investment advisor, financial planner, nor legal or tax professional and details here are of an opinion and general nature and should not be relied upon for individual circumstances. As this is a Beta product, we look forward to hearing your feedback and improvements on this incredibly exciting tool to feedback@jetalbert.com.</Typography>
                </Box>
              </Container>
              <Box my={8}>
                <AdSection/>
              </Box>
            </Container>
          </section>
            <Footer/>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
