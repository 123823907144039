import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import {FormControl, Select, MenuItem, Box, Container, Grid, Typography, Link} from '@material-ui/core/';
import {Card, CardMedia} from '@material-ui/core';
import {createClient} from "contentful";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    questionContainer: {
        padding: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            padding:'32px 0px'
        }
    },
    frontImg: {
        width:220,
        height:260,
        [theme.breakpoints.down('xs')]: {
            width:143,
            height:170,
          },
    },
    backImg: {
        width:290,
        height:290,
        [theme.breakpoints.down('xs')]: {
            width:189,
            height:189,
          },
    },
    frontImgContainer: {
        display:'inline-block',
        position:'absolute',
        zIndex:3,
        left:100,
        [theme.breakpoints.down('xs')]: {
            left:0,
            '& .MuiLink-root': {
                display: 'none'
            }
        }
    },
    backImgContainer: {
        display:'inline-block',
        position:'absolute',
        zIndex:2,
        left:210,
        top:-130,
        [theme.breakpoints.down('xs')]: {
            left:95,
            top:-83,
            '& .MuiLink-root': {
                display: 'none'
            }
        }
    },
    stackedImgContainer: {
        marginTop:75,
        position: 'relative',
        height: 175,
        maxWidth: 510,
        // padding:"0 50px",
        [theme.breakpoints.down('xs')]: {
             padding:"0",
             maxWidth: 270
        }
    },
    gettingStartedBox: {
        height: 65,
        width: 80,
        left: -50,
        top: 30,
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            top:0,
            left:0,
            width:83,
            height:40,
            margin:'auto',
            paddingBottom: 60
        }
    },
    mobOnly: {
        [theme.breakpoints.up('sm')]: {
            display:'none'
        }
    },
    deskOnly: {
        [theme.breakpoints.down('xs')]: {
            display:'none'
        }
    }
});

class SearchQuestions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arrival: "",
            departure: "",
            imgUrl1: "images/front-img@3x.png",
            captionText1: "Image 1 Caption",
            captionLink1: "#",
            imgUrl2: "images/back-img@3x.png",
            captionText2: "Image 2 Caption",
            captionLink2: "#",
        };
        this.contentfulClient = createClient( {
            accessToken:'BqbJ6qTW1C2hUzzjN2hOzWh4JWGjZl9IVUws39BXyZs',
            space:'gsutkxukxirj'
          })
    }

    componentDidMount() {
        this.contentfulClient.getEntries({content_type:"subHero"})
            .then(response => {
                const subheroObj = response.items[0].fields;
                let newState = this.state;
                newState.captionText1 = subheroObj["captionFront"];
                newState.captionText2 = subheroObj["captionBack"];
                newState.captionLink1 = subheroObj["urlFront"];
                newState.captionLink2 = subheroObj["urlBack"];
                newState.imgUrl1 = subheroObj.imageFront.fields.file.url;
                newState.imgUrl2 = subheroObj.imageBack.fields.file.url;
                this.setState(newState);
            })
    }

    handleDepartureChange(event) {
        // Forward on to handler
        const departureRegion = event.target.value;
        const newState = this.state;
        newState.departure = departureRegion;
        this.setState(newState);
        this.props.onUserPreferenceUpdate(event, undefined, departureRegion)
    }

    handleArrivalChange(event) {
        const arrivalRegion = event.target.value;
        const newState = this.state;
        newState.arrival = arrivalRegion;
        this.setState(newState);
        this.props.onUserPreferenceUpdate(event, arrivalRegion, undefined);
    }

    render() {
        const { classes } = this.props;
        // const captionLinkText1 = "Lufthansa First Class";
        // const captionLinkText2 = "Mandapa Ritz-Carlton Reserve";
        return (
            <Grid container>
                <Grid item sm={6} xs={12}>
                    <Container className={classes.questionContainer} style={{marginTop:12}}>
                        <Box className={classes.gettingStartedBox}>
                            <img className={classes.deskOnly} src="images/get-started-arc@3x.png" alt='' style={{width:91, height:74}}></img>
                            <img className={classes.mobOnly} src="images/get-started@3x.png" alt='' style={{width:83, height:40}}></img>
                        </Box>
                        <Typography variant="h4">Where are you flying from?</Typography>
                        <FormControl className={classes.formControl}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                displayEmpty
                                value={this.state.departure}
                                onChange={this.handleDepartureChange.bind(this)}
                                >
                                <MenuItem value="" disabled>Select an airport</MenuItem>
                                <MenuItem value={"Atlanta"}>Atlanta</MenuItem>
                                <MenuItem value={"Boston"}>Boston</MenuItem>
                                <MenuItem value={"Chicago"}>Chicago</MenuItem>
                                <MenuItem value={"Dallas"}>Dallas</MenuItem>
                                <MenuItem value={"Denver"}>Denver</MenuItem>
                                <MenuItem value={"Detroit"}>Detroit</MenuItem>
                                <MenuItem value={"Houston"}>Houston</MenuItem>
                                <MenuItem value={"Los Angeles"}>Los Angeles</MenuItem>
                                <MenuItem value={"Miami"}>Miami</MenuItem>
                                <MenuItem value={"Minneapolis"}>Minneapolis</MenuItem>
                                <MenuItem value={"New York"}>New York</MenuItem>
                                <MenuItem value={"Newark"}>Newark</MenuItem>
                                <MenuItem value={"Seattle"}>Seattle</MenuItem>
                                <MenuItem value={"San Francisco"}>San Francisco</MenuItem>
                                <MenuItem value={"Washington DC"}>Washington D.C.</MenuItem>
                                <MenuItem value={"Salt Lake City"}>Salt Lake City</MenuItem>
                            </Select>
                        </FormControl>
                    </Container>
                    <Container className={classes.questionContainer}>
                        
                        <Typography variant="h4">Where are you jetting off to?</Typography>
                        <FormControl className={classes.formControl}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                displayEmpty
                                placeholder="Select a region"
                                onChange={this.handleArrivalChange.bind(this)}
                                value={this.state.arrival}
                                >
                                <MenuItem value="" disabled>Select a region</MenuItem>
                                <MenuItem value={"Asia"}>Asia</MenuItem>
                                <MenuItem value={"Africa"}>Africa</MenuItem>
                                <MenuItem value={"Australia"}>Australia</MenuItem>
                                <MenuItem value={"Central America & The Caribbean"}>Central America &amp; The Caribbean</MenuItem>
                                <MenuItem value={"Europe"}>Europe</MenuItem>
                                <MenuItem value={"Middle East"}>Middle East</MenuItem>
                                <MenuItem value={"North America"}>North America</MenuItem>
                                <MenuItem value={"Oceania"}>Oceania</MenuItem>
                                <MenuItem value={"South America"}>South America</MenuItem>
                                <MenuItem value={"Southeast Asia"}>Southeast Asia</MenuItem>
                            </Select>
                        </FormControl>
                    </Container>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Box display="flex" alignItems='center' justifyItems='center'>
                        <Container className={classes.stackedImgContainer}>
                            <Box className={classes.frontImgContainer}>
                                <Card className={classes.frontImg} style={{backgroundColor:'transparent'}}>
                                    <CardMedia
                                    className={classes.media}
                                    image={this.state.imgUrl1}
                                    title="Singapore Airlines Business Class"
                                    style={{width:'inherit', height:'inherit'}}
                                    />
                                </Card>
                                <Box textAlign="right" pt={1}>
                                    <Link variant="subtitle2" href={this.state.captionLink1}>{this.state.captionText1}</Link>
                                </Box>  
                            </Box>
                            <Box className={classes.backImgContainer}>
                                <Card className={classes.backImg}  style={{backgroundColor:'transparent'}}>
                                    <CardMedia
                                    className={classes.media}
                                    image={this.state.imgUrl2}
                                    title="Singapore Airlines Business Class"
                                    style={{width:'inherit', height:'inherit'}}
                                    />
                                </Card>
                                <Box textAlign="right" pt={1}>
                                    <Link variant="subtitle2" href={this.state.captionLink2}>{this.state.captionText2}</Link>
                                </Box>  
                            </Box>
                        </Container>
                    </Box>
                    <Box textAlign="center" pt={1} className={classes.mobOnly}>
                        <Link variant="subtitle2" href={this.state.captionLink1}>{this.state.captionText1}</Link>
                    </Box>
                    <Box textAlign="center" pt={1} className={classes.mobOnly}>
                        <Link variant="subtitle2" href={this.state.captionLink2}>{this.state.captionText2}</Link>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(SearchQuestions);