import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import {Box, Container, Grid, Typography, TextField, Button} from '@material-ui/core/';

const styles = theme => ({
    root: {
        '&.Mui-focused fieldset': {
            borderColor: 'green', // TODO fix this not quite selecting
        },
    },
    combinedTextField: {
        width:400,
        borderRadius: 25,
        backgroundColor: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            width:260,
        },
        [theme.breakpoints.down('xs')]: {
            width:'calc(100vw - 50px)',
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.5)',
              },
            '&.Mui-focused fieldset': {
                borderColor:'#222222',
                borderWidth:'1px'
            },
        },
        '& .MuiInputBase-root input': {
            fontSize: 11,
            padding: `22px  14px`,
        }
    },
    combinedButton: {
        height:57, // perhaps this should be dynamic
        position:'absolute',
        left:290,
        width:130,
        borderTopRightRadius:100,
        borderBottomRightRadius:100,
        [theme.breakpoints.down('sm')]: {
            left:163,
            height:57
        },
        [theme.breakpoints.down('xs')]: {
            left:'calc(100vw - 50px - 130px)',
            height:57
        }
    },
    input: {
        borderRadius: 25,
    },
    feelingInspoBox: {
        height: 65,
        width: 80,
        left: -14,
        top: 40,
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            top:0,
            left:0,
            width:83,
            height:40,
            margin:'auto',
            paddingBottom: 70
        }
    },
    mobOnly: {
        [theme.breakpoints.up('xs')]: {
            display:'none'
        }
    },
    deskOnly: {
        [theme.breakpoints.down('xs')]: {
            display:'none'
        }
    },
    subscribeForm: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: 30,
            justifyContent:"space-around"
        }
    },
    noPadMobile: {
        [theme.breakpoints.down('xs')]: {
            padding:0
        }
    },
    alignCenterMobile: {
        [theme.breakpoints.down('xs')]: {
            textAlign:"center"
        }
    }
});

class EmailPoints extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email : "",
        };
    }

    handleInputChange(event) {
        // TODO This can also validate email address to sanitize input
        const newState  = { email: event.target.value}
        this.setState(newState);
    }

    render() {
        const { classes } = this.props;
        return (
           <Container className={classes.noPadMobile}>
                <Box className={classes.feelingInspoBox} mt={3}>
                    <img className={classes.deskOnly} src="images/feeling-inspired-arc@3x.png" alt='' style={{width:80, height:65}}></img>
                    <img className={classes.mobOnly} src="images/feeling-inspired@3x.png" alt='' style={{width:89, height:43}}></img>
                </Box>
               <Grid container direction="row" justify="center" alignItems="center">
                   <Grid item sm={3} xs={12}>
                       <Box textAlign="center">
                           <Typography variant="h5">Stay Connected</Typography>
                       </Box>
                   </Grid>
                   <Grid item sm={4} xs={12}>
                       <Box pl={2} className={classes.alignCenterMobile}>
                            <Typography variant="subtitle1">Sign-up for exciting aviation, travel, and points inspiration direct to your inbox.</Typography>
                       </Box>
                   </Grid>
                   <Grid item sm={5} xs={12}>
                       <Box>
                            <Box display="flex" justifyContent="center" className={classes.subscribeForm}>
                                <Box style={{position:'relative'}}>
                                    <form action="https://jetalbert.us10.list-manage.com/subscribe/post" method="POST">
                                        <input type="hidden" name="u" value="8031e11b8550cb4ae3198ea48"/>
                                        <input type="hidden" name="id" value="cc17a2daca"/>
                                        <TextField 
                                                    placeholder="EMAIL ADDRESS"
                                                    variant="outlined"
                                                    key={"TBD"}
                                                    type="email" 
                                                    name="EMAIL" 
                                                    id="MERGE0"
                                                    value={this.state.email}
                                                    className={classes.combinedTextField}
                                                    InputProps={{
                                                        className: classes.input,
                                                    }}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    />
                                        <Button color='primary'
                                                variant='contained'
                                                type="submit"
                                                className={classes.combinedButton}
                                                disableElevation>SUBSCRIBE</Button>
                                    </form>
                                </Box>
                            </Box>
                       </Box>
                   </Grid>
               </Grid>
           </Container>
        );
    }
}

export default  withStyles(styles)(EmailPoints);